<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix">
    <vs-input
      v-validate="'required|alpha_dash|min:3|max:24'"
      data-vv-validate-on="blur"
      label-placeholder="Name"
      name="channelName"
      placeholder="Channel Name"
      v-model="channelName"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first('channelName') }}</span>

    <vs-input
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      name="email"
      type="email"
      label-placeholder="Email"
      placeholder="Email"
      v-model="email"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
      ref="password"
      type="password"
      data-vv-validate-on="blur"
      v-validate="'required|min:8|max:64'"
      name="password"
      label-placeholder="Password"
      placeholder="Password"
      v-model="password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <vs-input
      type="password"
      v-validate="'min:8|max:64|confirmed:password'"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label-placeholder="Confirm Password"
      placeholder="Confirm Password"
      v-model="confirm_password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>
    <vs-button class="mt-6 w-full" @click="registerUserJWt" :disabled="!validateForm">Sign-Up</vs-button>
    <br />
    <br />

    <p>By clicking Sign Up, you agree to our <a href="https://www.hoverlay.com/terms/">Terms</a></p>
    <p>Already have an account? <a class="mt-6" href="/pages/login">Login instead</a></p>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  data() {
    return {
      channelName: '',
      email: '',
      password: '',
      confirm_password: '',
      isTermsConditionAccepted: true,
    }
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.channelName !== '' &&
        this.email !== '' &&
        this.password !== '' &&
        this.confirm_password !== '' &&
        this.isTermsConditionAccepted === true
      )
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        })

        return false
      }
      return true
    },
    async registerUserJWt() {
      // If form is not validated or user is already login return
      // if (!this.validateForm || !this.checkLogin()) return
      const payload = {
        userDetails: {
          username: this.channelName,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password,
        },
        notify: this.$vs.notify,
      }
      console.log('payload')
      console.log(payload)

      try {
        var res = await this.$store.dispatch('auth/registerUserJWT', payload)
        console.log('Success')
        this.$vs.notify({
          time: 5000,
          title: 'Success',
          text: 'Account creation completed',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        })
        await this.$store.dispatch('auth/loginJWT', { userDetails: { email: this.email, password: this.password } })
      } catch (error) {
        console.log('FAIL')
        console.log(JSON.parse(error))
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Error',
          text: JSON.parse(error)['errors'][0],
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'danger',
        })
      }
    },
  },
}
</script>
